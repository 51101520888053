.ok-groups {
	margin-top: $modular-scale *2em;

	.section-title {
		color: $color-light;
		background-color: $color-ok;
		margin: 0;
		line-height: $modular-scale-px *1;
		text-align: left;
		padding: 1em 3.5%;

		@media ( min-width: 768px ) {
			line-height: $modular-scale-px *3;
			font-size: $modular-scale *1em;
			padding: 0 0 0 14%;
		}

		@media ( min-width: 992px ) { padding-left: 17.5%; }
		@media ( min-width: 1200px ) { padding-left: 20%; }

		.fa {
			font-size: $modular-scale *1em;
			position: relative;
			top: 2px;
			margin-right: .15em;
			width: 1em;

			@media ( min-width: 768px ) { top: 3px; margin-left: -$modular-scale *.75em; }
		}
	}

	.group-list {
		padding: 0;
		margin: $modular-scale *.75em auto;
		list-style: none;
		width: 90%;
		-webkit-font-smoothing: subpixel-antialiased;

		@media ( min-width: 480px ) { display: flex; flex-flow: row wrap; }

		@media ( min-width: 768px ) { width: 85%; margin: $modular-scale *1em auto; }

		@media ( min-width: 992px ) { width: 75%; margin: $modular-scale *1.25em auto }

		&__item {
			margin-bottom: $modular-scale *.5em;

			@media ( min-width: 480px ) { width: 50%; }

			@media ( min-width: 1200px ) { width: 25%; }

			&-link {
				text-decoration: none;
				color: darken( $color-ok, 40% );
				display: flex;
				align-items: center;
				height: $modular-scale *2.5em;
				padding: $modular-scale *.25em;

				@media ( min-width: 992px ) { font-size: $base-font-size; }

				@media ( min-width: 1200px ) {
					flex-direction: column;
					text-align: center;
					height: $modular-scale-px *8;
				}
			}

			&-image {
				display: block;
				height: 50px;
				width: 50px;
				min-width: 50px;
				margin-right: $modular-scale *.5em;
				background-color: $color-ok;

				@media ( min-width: 1200px ) {
					margin: 0 auto $modular-scale-px *.5;
					width: 128px;
					height: 128px;
				}
			}

			&-name {
				font-size: 14px;
				line-height: $base-font-size;

				@media ( min-width: 1200px ) { max-width: $modular-scale-px *6; }

				&:hover, &:focus { text-decoration: underline; }
			}
		}
	}
}