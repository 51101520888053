// Typography

	// Scales
	$perfect-fourth: 1.333; // 3:4 
	$aug-fourth: 1.414; // 1:sqrt(2)
	$perfect-fifth: 1.5; // 2:3 
	$golden-section: 1.618; //1:1.618

$base-font-size: 16px;
$modular-scale: $golden-section;

$modular-scale-px: $modular-scale * $base-font-size;

$sans-serif-fontstack: 'OpenSans', Helvetica, Verdana, sans-serif;


$color-light: #fff;
$color-dark: #222;
// $color-primary: #00d44b;
$color-primary: #00a16e;
// $color-supporting: #000;
// $color-accent: #000;

$color-vk: #507299;
$color-ok: #ee8208;


//Material Design motion curves
$standard-curve: cubic-bezier( 0.4, 0.0, 0.2, 1 );
$deceleration-curve: cubic-bezier( 0.0, 0.0, 0.2, 1 );
$acceleration-curve: cubic-bezier( 0.4, 0.0, 1, 1 );
$sharp-curve: cubic-bezier( 0.4, 0.0, 0.6, 1 );

$mild-acceleration-curve: cubic-bezier(0.07, 0.28, 0.45, 1.19);

$tympanus-curve-a: cubic-bezier(0.25,0.25,0.325,1.39);
$tympanus-curve-b: cubic-bezier(0.6,0,0.4,1);

$main-curve: $tympanus-curve-b;