.main-resources {
	.site-list {
		width: 90%;
		padding-left: 0;
		margin: $modular-scale-px *.5 auto $modular-scale-px;
		list-style-type: none;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@media ( min-width: 992px ) { width: 80%; }

		@media ( min-width: 1200px ) { width: 1000px; }

		&__item {
			text-align: center;
			width: 33.333%;

			@media ( min-width: 768px ) { width: 25%; }

			.fa {
				font-size: $modular-scale *1.25em;
				line-height: $modular-scale-px;
				color: $color-primary;
				transition: color .3s $main-curve;

				@media (min-width: 768px) {
					font-size: $modular-scale *1.75em;
					line-height: $modular-scale-px *1.75;
				}
			}

			&-name {
				font-size: $base-font-size *.75;
				margin-top: $modular-scale-px *.5;
				line-height: 1.333;
				transition: color .3s $main-curve;

				@media (min-width: 768px) { font-size: $base-font-size *.85; }
			}

			&-link {
				margin: 0 auto;
				max-width: $modular-scale *4em;
				border: 1px solid transparent;
				padding: $modular-scale-px *.85 $modular-scale-px *.25 $modular-scale-px *.65;
				display: block;
				text-decoration: none;
				color: #666;
				border-radius: 3px;
				transition: background-color .3s $main-curve, border-color .3s $main-curve;

				@media ( min-width: 992px ) { max-width: $modular-scale *5em; }

				&:hover, &:focus {
					background-color: rgba( $color-primary, .15 );
					border-color: rgba( $color-primary, .25 );

					> div { color: darken( $color-primary, 25% ); }

					> .fa { color: darken( $color-primary, 5% ); }
				}

				&:active { background-color: rgba( $color-primary, .05 ); }
			}
		}
	}
}