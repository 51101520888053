
html, body {
	font: #{ $base-font-size } / #{ $modular-scale-px };
	font-family: $sans-serif-fontstack !important;
	position: relative;
	width: 100%;
	color: $color-dark;
	padding: 0 !important;
	margin: 0;
	background-color: $color-light;
	-webkit-font-smoothing: antialiased;
}

// custom resets
* { box-sizing: border-box; }

:focus { outline: none; }

img { max-width: 100%; } // responsive images helper

table { border-spacing: 0; border-collapse: collapse; }

address { font-style: normal; }

button { border: none; background-color: transparent; }

// @media ( min-width: 1200px ) {
// 	::-moz-selection { background: $color-primary; text-shadow: none; color: $color-light; }

// 	::selection { background: $color-primary; text-shadow: none; color: $color-light; }
// }


//Remove the gap between audio, canvas, iframes,
//images, videos and the bottom of their containers:
//https://github.com/h5bp/html5-boilerplate/issues/440

audio,
canvas,
iframe,
img,
svg,
video { vertical-align: middle; }


//Browser Upgrade Prompt

.browserupgrade { margin: .2em 0; background: #ccc; color: #000; padding: 0.2em 0; }

.page-title {
	margin: $modular-scale-px 0 $modular-scale-px *.75;
	font-size: $modular-scale-px;
	font-weight: 400;
	letter-spacing: .025em;
	text-transform: uppercase;
	text-align: center;

	@media (min-width: 768px) {
		font-size: $modular-scale * $modular-scale-px;
		margin: $modular-scale-px *1.75 0 $modular-scale-px *1.5;
	}

	+ .section-title { margin-top: -$modular-scale-px *.5; }
}

.section-title {
	text-align: center;
	font-weight: 400;
	font-size: $modular-scale *.618em;
	color: lighten( $color-dark, 35% );
	margin: $modular-scale-px *.25 0;

	@media (min-width: 768px) {
		margin: $modular-scale-px *.5 0 $modular-scale-px;
		font-size: $modular-scale *.75em;
	}

	@media (min-width: 1200px) {
		margin-bottom: $modular-scale-px *2;
	}
}